import React from "react";
import { Link } from "react-router-dom";
import SecondaryButton from "../components/SecondaryButton.js";
import Card from "../components/Card.js";
import Marquee from "../components/Marquee.js";
import Socials from "../components/Socials.js";
import practicesvg from "../img/practice.svg";
import Practice from "../components/Practice.js";
import img1 from "../img/1.jpg";
import img9 from "../img/7.jpg";
import img13 from "../img/12.jpg";
import Blog from "../components/Blog.js";

export default function Home() {
  return (
    <>
      <section className="hero px-9 py-5 stack">
        <div>
          <h1 data-animation="fade-in" className="impress light hidden">
            Your Issues
            <br />
            Become Ours
            <br />
            To <span className="italic bold">Resolve</span>
          </h1>
          <Link to="/contact">
            <SecondaryButton text="Get in touch" />
          </Link>
        </div>
        <p data-animation="fade-in" className="w-20 mw-100 right m-center hidden">
          We are a Boutique litigation firm with extensive experience fighting
          for our clients.
        </p>
      </section>
      <section className="px-9 py-5 spaced-flex gap-5 stack">
        <div>
          <div className="short">
            <h5 className="light">Our Legacy</h5>
            <h2>About Us</h2>
          </div>
          <p>
            Yorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis.
          </p>
        </div>
        <Card title="Customers served" num="500" symbol="+" />
        <Card title="Years in Service" num="25" symbol="+" />
        <Card title="Placements Covered" num="340" symbol="+" />
      </section>
      <Marquee direction="marquee-right" />
      <section id="About" className="px-9 py-9 stack center-stack">
        <div className="column-flex gap-5">
          <div className="short m-center">
            <h5 data-animation="fade-left" className="light hidden">
              Our Mission
            </h5>
            <h1 data-animation="fade-left" className="impress light hidden">
              We stand
              <br />
              for<span className="italic bold">You</span>
            </h1>
          </div>
          <div className="column-flex gap-2">
            <button className="transparent-button">
              <span data-animation="fade-in" className="hidden">LITIGATE</span>
            </button>
            <button className="transparent-button">
              <span data-animation="fade-in" className="hidden">ADVOCATE</span>
            </button>
            <button className="transparent-button">
              <span data-animation="fade-in" className="hidden">PROTECT</span>
            </button>
          </div>
          <div className="center-flex middle-flex">
            <Socials />
          </div>
        </div>
        <div className="column-flex  w-40 mw-100 m-center mmy-5">
          <p className="light hidden" data-animation="slide-left">
            Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis. Class
            aptent taciti sociosqu ad litora tas orquent{" "}
          </p>
        </div>
      </section>
      <Marquee direction="marquee-left" />
      <section className="py-9 px-9">
        <div>
          <img className="practicesvg" src={practicesvg}></img>
          <p className="light center mt-1 w-100 hidden" data-animation="typewriter">
            Jorem ipsum dolor sit amet, consectetur adipiscing elit niunc
            vulputate libero et velit interdum, ac aliquet odio makakhsd oka
            isdiuy ndie a orem
          </p>
        </div>
        <div className="column-flex gap-5 my-9 mgap-5">
          <Practice
            img={img1}
            title="Litigation"
            content="Our litigation team resolves complex legal disputes with expert representation and strategic advocacy. From commercial disputes to personal injury claims, we ensure your interests are robustly defended in and out of the courtroom."
            link="Practice/Litigation"
          />
          <Practice
            img={img9}
            title="Employment and Whistleblowing"
            content="We offer expert legal support for employment issues and whistleblowing cases. We handle wrongful termination, discrimination, and harassment while protecting whistleblowers who report misconduct, ensuring their rights and concerns are addressed."
            link="Practice/EnW"
          />
          <Practice
            img={img13}
            title="Client Service"
            content="We provide exceptional client service with personalized legal solutions. Our focus is on clear communication, proactive problem-solving, and delivering a supportive experience throughout the legal process."
            link="Practice/Client Service"
          />
        </div>
      </section>
      <section className="py-9 px-9 bg-primary-4">
        <Blog />
      </section>
    </>
  );
}
