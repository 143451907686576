import React from "react";

export default function SecondaryButton(props) {
  return (
    <div className="center-flex  gap-5">
      <button className="secondary-button">{props.text}</button>
      <svg
        width="42"
        height="43"
        viewBox="0 0 42 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="42" height="42" rx="10" fill="#111111" />
        <path
          d="M12 30.5L30 12.5M30 12.5H13.8M30 12.5V28.7"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
