import React, { useEffect, useState } from "react";
import logo from "../Logo.png";
import PrimaryButton from "./PrimaryButton";
import { NavLink, useLocation } from "react-router-dom";
import { addClassOnInViewport } from "../index.js";
import Sidebar from "./Sidebar"; // Import the Sidebar component

export default function Navbar() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    addClassOnInViewport();
  }, [location.pathname]);

  return (
    <div className="Navbar mt-7">
      <img src={logo} className="Logo" alt="logo" />
      <ul className="menu">
        <li>
          <NavLink className="animate-on-view" to="/">Home</NavLink>
        </li>
        <li>
          <NavLink className="animate-on-view" to="/About">About</NavLink>
        </li>
        <li>
          <NavLink className="animate-on-view" to="/Team">Team</NavLink>
        </li>
        <li>
          <NavLink className="animate-on-view" to="/Practice/Litigation">Practice</NavLink>
        </li>
        <li>
          <NavLink className="animate-on-view" to="/Contact">Contact</NavLink>
        </li>
      </ul>
      <PrimaryButton text="Get in touch" link="contact" />
      <div className="hamburger-menu" onClick={toggleSidebar}>
        &#9776;
      </div>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
}
