import React from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import BlogData from "../data/blogs.json";

const BlogPost = () => {
  let { blogname } = useParams(); // Get the postId parameter from the URL

  // Find the blog post by its ID or title
  const post = BlogData.find((item) => item.Title === blogname);

  if (!post) {
    return <div>Post not found</div>;
  }

  const bgStyle = {
    backgroundImage: `url(${post.FeaturedImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <>
      <section className="px-9 py-8">
        <div className="featured-image" style={bgStyle}>
          <h1 className="light-1">{post.Title}</h1>
        </div>
      </section>
      <section className="mt-8 py-5 px-9 bg-dark-2">
        <Breadcrumbs
          breadcrumbs={[
            { link: "/", linkName: "Home" },
            { link: "/Blogs", linkName: "Blogs" },
            { link: `/post/${blogname}`, linkName: post.Title },
          ]}
        />
      </section>
      <section className="px-9 py-9 practice-data">
        <div dangerouslySetInnerHTML={{ __html: post.Content }} />
      </section>
    </>
  );
};

export default BlogPost;
