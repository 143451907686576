import React, { useEffect, useState, useRef } from "react";
import BlogData from "../data/blogs.json"; // Import your blog data


export default function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const startX = useRef(null); // To track the start position of the swipe

  useEffect(() => {
    setBlogs(BlogData);
    setupDots(blogs.length);
  }, [blogs.length]);

  const setupDots = (totalCards) => {
    const dotsContainer = document.querySelector(".dots-container");
    dotsContainer.innerHTML = "";

    for (let i = 0; i < totalCards; i++) {
      const dot = document.createElement("span");
      dot.classList.add("dot");
      if (i === 0) dot.classList.add("active-dot");
      dot.addEventListener("click", () => goToCard(i));
      dotsContainer.appendChild(dot);
    }
  };

  const goToCard = (index) => {
    setCurrentIndex(index);
    updateCardPosition(index);
    updateActiveDot(index);
  };

  const updateCardPosition = (index) => {
    const blogCards = document.querySelectorAll(".blog-card");
    let cardMoveAmount = 390; // Default move amount
    if (window.innerWidth < 900) {
      cardMoveAmount = 420; // Adjusted move amount for screens less than 900px wide
    }
    const newPosition = -index * cardMoveAmount;
    blogCards.forEach((card) => {
      card.style.transform = `translateX(${newPosition}px)`;
    });
  };
  

  const updateActiveDot = (index) => {
    const dots = document.querySelectorAll(".dot");
    dots.forEach((dot, i) => {
      dot.classList.toggle("active-dot", i === index);
    });
  };

  const handleTouchStart = (event) => {
    startX.current = event.touches[0].clientX;
  };

  const handleTouchMove = (event) => {
    if (startX.current === null) return;
    const endX = event.touches[0].clientX;
    const difference = startX.current - endX;

    if (Math.abs(difference) > 50) {
      handleSwipe(difference);
      startX.current = null; // Reset startX after swipe
    }
  };

  const handleSwipe = (difference) => {
    if (difference > 50 && currentIndex < blogs.length - 1) {
      goToCard(currentIndex + 1);
    } else if (difference < -50 && currentIndex > 0) {
      goToCard(currentIndex - 1);
    }
  };

  return (
    <>
      <div className="short">
        <h5 className="light">Our Blog</h5>
        <h2>Latest News</h2>
      </div>
      <div
        className="blog-container"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        {blogs.length > 0 ? (
          blogs.map((blog, index) => (
            <div key={index} className="blog-card">
              <img src={blog.FeaturedImage} alt={blog.Title} />
              <h2>{blog.Title}</h2>
              <p>{blog.Excerpt}</p>
              <span className="bold mt-3">
                <a href={`/Blogs/${blog.Title}`}>Read More</a>
              </span>
            </div>
          ))
        ) : (
          <p>No blog posts available.</p>
        )}
      </div>
      <div className="dots-container"></div>
    </>
  );
}
