import React, { useEffect, useState } from "react";

export default function Card(props) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const cardElement = document.querySelector(`[data-animation="fade-in"]`);

    const handleAnimationEnd = () => {
      let start = 0;
      const end = parseInt(props.num, 10);
      if (start === end) return;

      let totalDuration = end < 100 ? 2000 : 2000; // Faster duration for 3-digit numbers and above
      let incrementTime = Math.abs(Math.floor(totalDuration / end));
      const incrementStep = end >= 100 ? 10 : 1; // Increment by 10 for 3-digit numbers and above

      const timer = setInterval(() => {
        start += incrementStep;
        if (start >= end) {
          setCount(end); // Ensure the counter reaches the exact end value
          clearInterval(timer);
        } else {
          setCount(start);
        }
      }, incrementTime);

      return () => clearInterval(timer);
    };

    cardElement.addEventListener('animationend', handleAnimationEnd);

    return () => {
      cardElement.removeEventListener('animationend', handleAnimationEnd);
    };
  }, [props.num]);

  return (
    <div className="card hidden" data-animation="fade-in">
      <h3 className="impress light">
        {count}&nbsp;{props.symbol}
      </h3>
      <div className="card-title">
        <h2 className="card-data light">{props.title}</h2>
        <svg
          className="card-arrow"
          width="58"
          height="58"
          viewBox="0 0 58 58"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="1"
            y="1"
            width="56"
            height="56"
            rx="28"
            stroke="white"
            stroke-width="2"
          />
          <path
            d="M20 38L38 20M38 20H21.8M38 20V36.2"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}
