import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Teamcard from "../components/Teamcard";
import Marquee from "../components/Marquee";
import tall from "../img/Professionals.svg";
import teamData from "../data/teams.json";

const nameToImageMap = {
  "Russell Bogart": require("../img/russell-full.png"),
  "Stuart Kagen": require("../img/stuart1.png"),
  "Joel Taylor": require("../img/joel1.png"),
  "Sam Caspersen": require("../img/sam1.png"),
  // "Mark Weinberg": require("../img/mark.png"), // Added Mark Weinberg image
  // Add other mappings as needed
};

const linkToMap = {
  "Russell Bogart": "/team/Russell Bogart",
  "Stuart Kagen": "/team/Stuart Kagen",
  "Joel Taylor": "/team/Joel Taylor",
  "Sam Caspersen": "/team/Sam Caspersen",
  "Mark Weinberg": "/team/Mark Weinberg", // Added Mark Weinberg link
  // Add other mappings as needed
};

const bg =
  "url('https://images.unsplash.com/photo-1507964878701-3fc78c90510f?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')";

const breadcrumbs = [
  { link: "/team/Stuart Kagen", linkName: "Stuart Kagen" },
  { link: "/team/Sam Caspersen", linkName: "Sam Caspersen" },
  { link: "/team/Russell Bogart", linkName: "Russell Bogart" },
  { link: "/team/Mark Weinberg", linkName: "Mark Weinberg" },
  { link: "/team/Joel Taylor", linkName: "Joel Taylor" }
];

const trimTextTo10Words = (input) => {
  // Handle non-string and non-array inputs gracefully
  if (typeof input !== 'string' && !Array.isArray(input)) return '';

  const text = Array.isArray(input) ? input.join(' ') : input;
  const words = text.trim().split(' ');

  if (words.length > 10) {
    return words.slice(0, 10).join(' ') + '...';
  }

  return text;
};



export default function Team() {
  return (
    <>
      <section className="px-9 py-8">
        <div
          className="featured-image"
          style={{
            backgroundImage: bg,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 className="dark-1">Meet the team</h1>
          <p className="dark-1 w-40 center mt-3 mw-100">
            We have a dedicated team of legal experts at our law firm, committed
            to providing exceptional service and personalized solutions for all
            your legal needs.
          </p>
        </div>
      </section>
      <section className="mt-8 py-5 px-9 bg-dark-2">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </section>
      <Marquee direction="marquee-right" />
      <section className="px-9 py-9 bg-primary-4">
        <img src={tall} style={{ width: "100%" }} alt="Legal professionals" />
        <div className="mt-8 spaced-flex stack gap-3 center-stack">
          {teamData.attorneys.map((attorney, index) => (
            <Teamcard
              key={index}
              image={nameToImageMap[attorney.name]}
              name={attorney.name}
              role={attorney.role}
              text={trimTextTo10Words(attorney.profile.overview)} // Inline trimming
              link={linkToMap[attorney.name]}
            />
          ))}
        </div>
      </section>
      <Marquee direction="marquee-left" />
      <section className="px-9 py-9">
        <p>
          Our attorneys prepare each case for trial from the beginning. Our firm
          has confidently brought cases to judges and juries in proceedings
          across the country when prudent. This focus enhances our ability to
          assist our clients in resolving matters successfully. Attorneys at our
          firm have received coverage in the New York Law Journal and elsewhere
          for their success in advancing our clients’ goals.
        </p>
        <p className="mt-5">
          That is why clients who face formidable legal challenges come to us
          with their problems, their cases, and their businesses. We are
          entrusted with cases across the United States that touch upon
          partnership disputes, patent issues, shareholder freezeout mergers,
          and many other issues. At our firm each case is unique, and we always
          work hard to ensure that we can obtain the best result.
        </p>
        <p className="mt-5">
          Our decades of experience have taught us how to defend each kind of
          case, and how to obtain dismissal before trial in many instances. When
          trial cannot be avoided, our clients benefit from our intense focus
          and impressive diligence.
        </p>
      </section>
    </>
  );
}
