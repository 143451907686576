import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Function to check if an element is in the viewport
function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// Function to add a class to elements with a specific data attribute if they are in the viewport
export function addClassOnInViewport() {
  // Select all elements with the data-animation attribute
  const elements = document.querySelectorAll("[data-animation]");

  elements.forEach((element) => {
    // Get the value of the data-animation attribute
    const animationClass = element.getAttribute("data-animation");

    // Check if the element is in the viewport
    if (isInViewport(element)) {
      // Add the class from the data-animation attribute if it's not already applied
      if (!element.classList.contains(animationClass)) {
        element.classList.add(animationClass);
      }
    }
  });
}

// Add event listeners for scroll and resize to check elements' positions
window.addEventListener("scroll", addClassOnInViewport);
window.addEventListener("resize", addClassOnInViewport);
window.addEventListener("load", addClassOnInViewport);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
