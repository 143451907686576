import React from "react";
import { NavLink } from "react-router-dom";

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <ul className="bread-crumbs wrap">
      {breadcrumbs.map((breadcrumb, index) => (
        <li key={index}>
          <NavLink to={breadcrumb.link}>{breadcrumb.linkName}</NavLink>
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
