import React from "react";
import Socials from "./Socials";
import { Link, NavLink } from "react-router-dom";
import Mail from "../img/Mail.svg";
import Web from "../img/Web.svg";

export default function Footer() {
  return (
    <div className="footer">
      <div className="spaced-flex stack">
        <div className="short">
          <h5>We are always listening</h5>
          <h1 className="impress">Get in touch</h1>
        </div>
        <ul className="icon-list">
          <a href="mailto:">
            <li>
              <h2 className="light light-1">Email</h2>
              <img src={Mail}></img>
            </li>
          </a>
          <Link to="/contact">
            <li>
              <h2 className="light light-1">Web</h2>
              <img src={Web}></img>
            </li>
          </Link>
        </ul>
      </div>
      <div className="spaced-flex mt-7 align-end stack m-center center-stack mgap-10">
        <div>
          <h3>Our Office</h3>
          <p>
            New York City 750 Third Ave,
            <br /> 24th Floor New York,
            <br /> NY 10017
            <br />
            <br />
            <span className="bold">Phone</span>: +1-212-880-2045
          </p>
        </div>
        <Socials />
      </div>
      <div></div>

      <div className="spaced-flex mt-9 stack center-stack">
        <h3>KCB Firm</h3>
        <h3>Al rights reserved</h3>
      </div>
    </div>
  );
}
