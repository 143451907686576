import React from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import PracticeData from "../data/practice.json";

const bg =
  "url('https://images.unsplash.com/photo-1713350472373-fb79157678b8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')";

const breadcrumbs = [
  { link: "/Practice/Litigation", linkName: "Litigation" },
  { link: "/Practice/EnW", linkName: "E&W" },
  { link: "/Practice/Client Service", linkName: "Client Service" },
];

const Practice = () => {
  let { practiceType, practiceName } = useParams();

  // Convert PracticeData to an array if it's not already
  const practiceDataArray = [];

  // Find the correct practiceData based on category and practiceName
  let practiceData;
  if (PracticeData[practiceType]) {
    practiceData = PracticeData[practiceType].find(
      (item) => item.title === practiceName
    );
  }  

  if (!practiceData) {
    return <div>Practice area not found for {practiceName}.</div>;
  }

  return (
    <>
      <section className="px-9 py-8">
        <div
          className="featured-image"
          style={{
            backgroundImage: bg,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 className="light-1">{practiceData.title}</h1>
          {/* Use practiceData.content as HTML dangerously set */}
        </div>
      </section>
      <section className="mt-8 py-5 px-9 bg-dark-2">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </section>
      <section className="px-9 py-9 practice-data">
        <div dangerouslySetInnerHTML={{ __html: practiceData.content }} />
      </section>
    </>
  );
};

export default Practice;
