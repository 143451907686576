import React from "react";

export default function Marquee(props) {
  return (
    <section className="marquee-section">
      <div className={props.direction}>
        <h4  className="marquee-item">Kagen</h4>
        <h4  className="marquee-item">Bogart</h4>
        <h4  className="marquee-item">Caspersen</h4>
        <h4  className="marquee-item">Kagen</h4>
        <h4  className="marquee-item">Bogart</h4>
        <h4  className="marquee-item">Caspersen</h4>
        <h4  className="marquee-item">Kagen</h4>
        <h4  className="marquee-item">Bogart</h4>
        <h4  className="marquee-item">Caspersen</h4>
      </div>
    </section>
  );
}
