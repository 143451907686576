import React from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs.js";
import SquareCard from "../components/Squarecard.js"; // Assuming you have a SquareCard component
import BlogData from "../data/blogs.json";

const bg =
  "url('https://images.unsplash.com/photo-1713350472373-fb79157678b8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')";

const breadcrumbs = [
  { link: "", linkName: "Home" },
  { link: "/Blogs", linkName: "Blog" },
];

const Blog = () => {

  // Ensure BlogData is correctly structured and contains the expected data
  console.log(BlogData); // Debugging output

  return (
    <>
      <section className="px-9 py-8">
        <div
          className="featured-image"
          style={{
            backgroundImage: bg,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 className="light-1 center">Welcome to our blogs</h1>
          <p className="light-1 w-40 center mt-4 mw-80">
            Get the latest information and updates about Laws and Practices
          </p>
        </div>
      </section>
      <section className="mt-8 py-5 px-9 bg-dark-2">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </section>

      <section  className="px-9 py-9 bg-primary-4 spaced-flex wrap gap-2">
        {/* Render square cards based on blogType */}
        {BlogData?.map((item, index) => (
          <SquareCard
            key={index}
            title={item.Title}
            link={item.Title}
            image={item.FeaturedImage}
          />
        ))}
      </section>
    </>
  );
};

export default Blog;
