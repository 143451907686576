import React from "react";
import image from "../img/Untitled design(61).png";
import Socialgrid from "../components/Socialgrid";
export default function Contact() {
  return (
    <>
      <section className="px-9 mt-8 mb-5 ">
        <div className="spaced-flex px-9 py-9 bg-primary-1 radius-3 clip">
          <div className="column-flex spaced-flex gap-8 ">
            <div>
              <h1 data-animation="fade-left" className="impress light light-1 hidden">
                Get in <span className="italic bold">Touch</span>
              </h1>
              <p data-animation="fade-left" className="light-1 w-70 hidden">
                for any queries feel free to reach out to us through our web
                from or email.
              </p>
            </div>
            <div className="light-1">
              <h4 data-animation="fade-right" className="hidden">TEL: +1-212-880-2045</h4>
              <h4 data-animation="fade-right" className="hidden">
                EMAIL: CONTACT@KAGENCASPERSEN.COM
              </h4>
            </div>
          </div>
          <div  data-animation="fade-up" className="hidden">
            <img
              className="hide"
              style={{
                position: "absolute",
                top: "15%",
                right: "3%",
                objectFit: "cover",
              }}
              src={image}
            ></img>
          </div>
        </div>
      </section>
      <section className="mb-9 px-9 spaced-flex gap-2 stack">
        <div  className="px-7 py-7 bg-tertiary-4 radius-2 column-flex spaced-flex">
          <h1 className="my-3 tertiary-1">Socials</h1>
          <Socialgrid />
          <div className="contact-grid"></div>
        </div>
        <div className="px-7 py-7 bg-primary-4 radius-2 w-100 mw-80">
          <h1 className="primary-1">Web Form</h1>
          <form action="" className="mt-8 web-form">
            <input
              className="py-4 px-4 my-2 radius-1 w-90"
              placeholder="ENTER YOUR EMAIL"
              type="text"
            />
            <input
              className="py-4 px-4 my-2 radius-1 w-90"
              placeholder="ENTER YOUR PHONE NUMBER"
              type="text"
            />
            <textarea
              name=""
              id=""
              className="py-4 px-4 my-2 radius-1 w-90"
            ></textarea>
            <input
              className="primary-button my-4 py-4 submit bg-primary-1 bold light-1 w-100"
              type="Submit"
              value="SEND"
            />
          </form>
        </div>
      </section>
      <section className="mb-9 mt-5 px-9">
        <p>
          {" "}
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius quas
          eaque numquam ea, illo dolore reprehenderit aspernatur nam maxime,
          nulla necessitatibus? Nostrum, iste deleniti nobis ipsum reiciendis
          doloribus possimus quisquam!
        </p>
      </section>
    </>
  );
}
