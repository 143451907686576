import React from "react";
import { Link } from "react-router-dom";
export default function Squarecard(props) {
  return (
    <Link to={props.link}>
      <div data-animation="fade-in" className=" hidden square-card">
        <img src={props.image}></img>
        <h3>{props.title}</h3>
      </div>
    </Link>
  );
}
