import React from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import SquareCard from "../components/Squarecard.js"; // Assuming you have a SquareCard component
import PracticeData from "../data/practice.json";

const bg =
  "url('https://images.unsplash.com/photo-1713350472373-fb79157678b8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')";

const breadcrumbs = [
  { link: "/Practice/Litigation", linkName: "Litigation" },
  { link: "/Practice/EnW", linkName: "E&W" },
  { link: "/Practice/Client Service", linkName: "Client Service" },
];
const nameToImageMap = {
  "Complex Commercial Litigation": require("../img/1.jpg"),
  "Securities Litigation": require("../img/2.jpg"),
  "Broker Dealer Disputes": require("../img/3.jpg"),
  "Partnership Disputes": require("../img/4.jpg"),
  "Intellectual Property Litigation": require("../img/5.jpg"),
  "Real Estate Litigation": require("../img/6.jpg"),
  "Bankruptcy Litigation": require("../img/7.jpg"),
  "Government and Internal Investigations": require("../img/8.jpg"),
  "Employment and Counseling": require("../img/9.jpg"),
  "Discrimination and Harassment": require("../img/10.jpg"),
  "Unfair Competition Litigation": require("../img/11.jpg"),
  "Whistleblower and Qui Tam Litigation": require("../img/12.jpg"),
  "Employment Contracts": require("../img/13.jpg"),
  "Outsourced General Counsel": require("../img/14.jpg"),
  "Crisis Management": require("../img/15.jpg"),
  "Private Investment Counseling": require("../img/16.jpg"),
};


const Practice = () => {
  let { practiceType } = useParams(); // Get the practiceType parameter from the URL

  return (
    <>
      <section className="px-9 py-8">
        <div
          className="featured-image"
          style={{
            backgroundImage: bg,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 className="light-1">All Practices</h1>
          <p className="light-1 mt-4 w-40 center mw-100">
            Our lawyers have litigated a number of unfair competition cases in
            federal and state court
          </p>
        </div>
      </section>
      <section className="mt-8 py-5 px-9 bg-dark-2">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </section>

      <section id="PracticeGrid" className="px-9 py-9 bg-primary-4">
        {/* Render square cards based on practiceType */}
        {PracticeData[practiceType]?.map((item, index) => (
          <SquareCard
            key={index}
            title={item.title}
            link={item.title}
            image={nameToImageMap[PracticeData[practiceType][index].title]}
          />
        ))}
      </section>
    </>
  );
};

export default Practice;
