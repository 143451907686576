import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import Mail from "../img/mail-dark.svg";
import Phone from "../img/Phone Dark.svg";
import TeamData from "../data/teams.json"; // Import JSON data
import { useParams } from "react-router-dom";

const nameToImageMap = {
  "Russell Bogart": require("../img/russell-full.png"),
  "Stuart Kagen": require("../img/stuart1.png"),
  "Joel Taylor": require("../img/joel1.png"),
  "Sam Caspersen": require("../img/sam1.png"),
  // Add other mappings as needed
};

const breadcrumbs = [
  { link: "/team/Stuart Kagen", linkName: "Stuart Kagen" },
  { link: "/team/Sam Caspersen", linkName: "Sam Caspersen" },
  { link: "/team/Russell Bogart", linkName: "Russell Bogart" },
  { link: "/team/Mark Weinberg", linkName: "Mark Weinberg" },
  { link: "/team/Joel Taylor", linkName: "Joel Taylor" },
];
export default function TeamSingle() {
  let { name } = useParams();

  // Extract attorney's data from TeamData
  const attorney = TeamData.attorneys.find(
    (attorney) => attorney.name === name
  );

  if (!attorney) {
    return (
      <div
        style={{
          width: "100%",
          height: "440px",
          textAlign: "center",
          padding: "100px 0",
          fontSize: "140px",
        }}
      >
        404
        <br />
        not found
      </div>
    );
  }

  const {
    contact,
    profile,
    credentials,
    representative_cases: representativeCases,
    representative_matters: representativeMatters,
    Decisions,
  } = attorney;
  const person = nameToImageMap[attorney.name];

  return (
    <>
      <section className="px-9 py-8" id="top">
        <div
          data-animation="fade-left"
          className="team-banner hidden"
          style={{
            backgroundSize: "30%",
            background: "var(--t3)",
            backgroundPosition: "100% 0",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="column-flex spaced-flex left">
            <div className="mb-5">
              <h1>{attorney.name}</h1>
            </div>
            <div className="colum-felx gap-1 dark-icon-list">
              <ul className="icon-list">
                {contact.email && (
                  <a href={`mailto:${contact.email}`}>
                    <li>
                      {" "}
                      <h2 className="light dark-1">Email</h2>
                      <img src={Mail} alt="Email icon" />
                    </li>
                  </a>
                )}
                <a href={`tel:${contact.phone}`}>
                  <li>
                    <h2 className="light dark-1">Contact</h2>
                    <img src={Phone} alt="Web icon" />
                  </li>
                </a>
              </ul>
            </div>
          </div>
          <img className="team-mate" src={person} alt={attorney.name} />
        </div>
      </section>
      <section className="mt-8 py-5 px-9 bg-dark-2">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
      </section>
      <section className="px-9 py-9">
        <h1 className="mb-5">Overview</h1>
        <div id="overview-container">
          {typeof profile.overview === "string" ? (
            <p className="mb-3">{profile.overview}</p>
          ) : (
            Array.isArray(profile.overview) &&
            profile.overview.map((paragraph, index) => (
              <p className="mb-3" key={index}>
                {paragraph}
              </p>
            ))
          )}
        </div>
      </section>
      <section className="px-9 py-9 bg-primary-4">
        <h1 className="mb-5">Education</h1>
        {credentials.education.map((edu, index) => (
          <div key={index} className="mb-5">
            <h4>{edu.institution}</h4>
            {edu.degree && (
              <p>
                {edu.degree} - {edu.year}
              </p>
            )}
          </div>
        ))}
        {credentials.bar_admissions && (
          <div className="mb-5">
            <h4>BAR ADMISSIONS</h4>
            {credentials.bar_admissions.map((admission, index) => (
              <h5 key={index} className="my-2">
                {admission}
              </h5>
            ))}
          </div>
        )}
      </section>

      {representativeCases && (
        <>
          <section className="px-9 py-9">
            <h1>Representative Cases</h1>
            {/* Appeals */}
            {representativeCases.appeals && (
              <div>
                <h3 className="mt-5">Appeals</h3>
                <ul className="list my-5">
                  {representativeCases.appeals.map((caseItem, index) => (
                    <li key={`appeals-${index}`}>{caseItem}</li>
                  ))}
                </ul>
              </div>
            )}
            {/* Single */}
            {representativeCases.single && (
              <div>
                <ul className="list my-5">
                  {representativeCases.single.map((caseItem, index) => (
                    <li key={`single-${index}`}>{caseItem}</li>
                  ))}
                </ul>
              </div>
            )}
            {/* Commercial and Federal Litigation */}
            {representativeCases.commercial_and_federal_litigation && (
              <div>
                <h3 className="mt-3">Commercial and Federal Litigation</h3>
                <ul className="list my-5">
                  {representativeCases.commercial_and_federal_litigation.map(
                    (caseItem, index) => (
                      <li key={`commercial-federal-${index}`}>{caseItem}</li>
                    )
                  )}
                </ul>
              </div>
            )}
            {/* Copyright, Trademark and Patent Cases */}
            {representativeCases.intellectual_property_cases && (
              <div>
                <h3 className="mt-3">Copyright, Trademark and Patent Cases</h3>
                <ul className="list my-5">
                  {representativeCases.intellectual_property_cases.map(
                    (caseItem, index) => (
                      <li key={`copyright-trademark-patent-${index}`}>
                        {caseItem}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            {/* Employment and Whistleblowing Cases */}
            {representativeCases.employment_and_whistleblowing_cases && (
              <div>
                <h3 className="mt-3">Employment and Whistleblowing Cases</h3>
                <ul className="list my-5">
                  {representativeCases.employment_and_whistleblowing_cases.map(
                    (caseItem, index) => (
                      <li key={`employment-whistleblowing-${index}`}>
                        {caseItem}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            {/* Commercial Litigation */}
            {representativeCases.commercial_litigation && (
              <div>
                <h3 className="mt-3">Commercial Litigation</h3>
                <ul className="list my-5">
                  {representativeCases.commercial_litigation.map(
                    (caseItem, index) => (
                      <li key={`commercial-litigation-${index}`}>
                        {caseItem.description}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            {/* Regulatory Matters */}
            {representativeCases.regulatory_matters && (
              <div>
                <h3 className="mt-3">Regulatory Matters</h3>
                <ul className="list my-5">
                  {representativeCases.regulatory_matters.map(
                    (caseItem, index) => (
                      <li key={`regulatory-matters-${index}`}>
                        {caseItem.description}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
            {/* Creditors' Rights */}
            {representativeCases.creditors_rights && (
              <div>
                <h3 className="mt-3">Creditors' Rights</h3>
                <ul className="list my-5">
                  {representativeCases.creditors_rights.map(
                    (caseItem, index) => (
                      <li key={`creditors-rights-${index}`}>
                        {caseItem.description}
                      </li>
                    )
                  )}
                </ul>
              </div>
            )}
          </section>
        </>
      )}
      {representativeMatters && (
        <section className="px-9 py-9 bg-tertiary-4">
          <h1 className="mb-5">Representative Matters</h1>
          <ul className="list my-5">
            {representativeMatters.map((matter, index) => (
              <li key={`matter-${index}`}>{matter.description}</li>
            ))}
          </ul>
        </section>
      )}
      {Decisions && (
        <section className="px-9 py-9 bg-tertiary-4">
          <h1 className="mb-5">Decisions</h1>
          <ul className="list my-5">
            {Decisions.map((decision, index) => (
              <li key={`decision-${index}`} className="mb-3">
                {decision}
              </li>
            ))}
          </ul>
        </section>
      )}
    </>
  );
}
