import "./App.css";
import "./Medai.css";
import "./Mobile.css";
import "./animations.css";
import About from "./pages/About.js";
import Navbar from "./components/Navbar.js";
import Sidebar from "./components/Sidebar.js";
import Home from "./pages/Home.js";
import Footer from "./components/Footer.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Practice from "./pages/Practice.js";
import Team from "./pages/Team.js";
import TeamSingle from "./pages/TeamSingle.js";
import PracticeSingle from "./pages/PracticeSingle.js";
import Contact from "./pages/Contact.js";
import Preloader from "./components/Preloader.js";
import Blog from "./pages/Blog.js";
import BlogSingle from "./pages/BlogSingle.js";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <header className="header">
             <Navbar />
            <Sidebar />
            <Sidebar />
          </header>
          <Home />
          <section className="px-9 light-1 bg-dark-1">
            <Footer />
          </section>
        </>
      ),
    },
    {
      path: "/About",
      element: (
        <>
          <header className="header">
             <Navbar />
            <Sidebar />
          </header>
          <About />
          <section className="px-9 light-1 bg-dark-1">
            <Footer />
          </section>
        </>
      ),
    },
    {
      path: "/Practice/:practiceType",
      element: (
        <>
          <header className="header">
             <Navbar />
            <Sidebar />
          </header>
          <Practice />
          <section className="px-9 light-1 bg-dark-1">
            <Footer />
          </section>
        </>
      ),
    },
    {
      path: "/Contact",
      element: (
        <>
          <header className="header">
             <Navbar />
            <Sidebar />
          </header>
          <Contact />
          <section className="px-9 light-1 bg-dark-1">
            <Footer />
          </section>
        </>
      ),
    },
    {
      path: "/Team",
      element: (
        <>
          <header className="header">
             <Navbar />
            <Sidebar />
          </header>
          <Team />
          <section className="px-9 light-1 bg-dark-1">
            <Footer />
          </section>
        </>
      ),
    },
    {
      path: "/Team/:name",
      element: (
        <>
          <header className="header">
             <Navbar />
            <Sidebar />
          </header>
          <TeamSingle />
          <section className="px-9 light-1 bg-dark-1">
            <Footer />
          </section>
        </>
      ),
    },
    {
      path: "/Practice/:practiceType/:practiceName",
      element: (
        <>
          <header className="header">
             <Navbar />
            <Sidebar />
          </header>
          <PracticeSingle />
          <section className="px-9 light-1 bg-dark-1">
            <Footer />
          </section>
        </>
      ),
    },
    {
      path: "/Blogs",
      element: (
        <>
          <header className="header">
             <Navbar />
            <Sidebar />
          </header>
          <Blog />
          <section className="px-9 light-1 bg-dark-1">
            <Footer />
          </section>
        </>
      ),
    },
    {
      path: "/Blogs/:blogname",
      element: (
        <>
          <header className="header">
             <Navbar />
            <Sidebar />
          </header>
          <BlogSingle />
          <section className="px-9 light-1 bg-dark-1">
            <Footer />
          </section>
        </>
      ),
    },
  ]);

  return (
    <>
      <Preloader />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
