import React from "react";
import { Link } from "react-router-dom";
import Card from "../components/Card.js";
import Socials from "../components/Socials.js";
import image from "../img/Building.jpg";
import banner from "../img/Banner.png";
import flag from "../img/flagamerica.png";
import Mail from "../img/mail-dark.svg";
import Web from "../img/web-dark.svg";
import Marquee from "../components/Marquee.js";
import Blog from "../components/Blog.js";
export default function About() {
  return (
    <>
      <section className="py-8 px-9">
        <div className="spaced-flex gap-4 align-mid stack mb-9">
          <div className="w-40 mw-100">
            <h1 data-animation="fade-left" className="light hidden">
              Get to know
            </h1>
            <h1 data-animation="fade-left" className="Bold hidden">
              About us
            </h1>
            <p data-animation="fade-left" className="hidden">
              Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos.
            </p>
          </div>
          <div style={{ height: "400px" }}>
            <img className="hidden"
              data-animation="fade-in"
              style={{
                height: "400px",
                objectFit: "cover",
                width: "100%",
                borderRadius: "6px",
              }}
              src={image}
            ></img>
          </div>
        </div>
        <div className="spaced-flex gap-4 align-mid reverse-stack mt-9">
          <div style={{ height: "400px" }}>
            <img className="hidden"
              data-animation="fade-in"
              style={{
                height: "400px",
                objectFit: "cover",

                width: "100%",
                borderRadius: "6px",
              }}
              src={flag}
            ></img>
          </div>
          <div className="w-40 mw-100">
            <p data-animation="fade-right"className="hidden" >
              Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis. Class
              aptent taciti sociosqu ad litora torquent per conubia nostra, per
              inceptos himenaeos.
            </p>
          </div>
        </div>
      </section>
      <section className="py-9 px-9 mt-9 bg-primary-4">
        <div className="spaced-flex gap-3 reverse-stack">
          <Card title="Customers served" num="500" symbol="+" />
          <Card title="Years in Service" num="25" symbol="+" />
          <Card title="Placements Covered" num="340" symbol="+" />
          <div className="colum-felx gap-1 dark-icon-list">
            <ul className="icon-list">
              <a href="mailto:">
                <li>
                  <h2 className="light dark-1">Email</h2>
                  <img src={Mail}></img>
                </li>
              </a>
              <Link to="/contact">
                <li>
                  <h2 className="light dark-1">Web</h2>
                  <img src={Web}></img>
                </li>
              </Link>
            </ul>
            <div className="mt-8 dark-socials">
              <Socials />
            </div>
          </div>
        </div>
      </section>

      <Marquee direction="marquee-right" />

      <section className="spaced-flex my-5 gap-5 px-9 align-mid stack center-stack">
        <img id="banner-image" src={banner}></img>
        <p data-animation="fade-left" className="hidden">
          Tconubia nostra, per inceptos himenaeos.”
        </p>
      </section>
      <Marquee direction="marquee-left" />
      <section className="bg-primary-4 px-9 py-9">
        <Blog />
      </section>
    </>
  );
}
