import React from "react";
import facebook from "../img/facebook.svg";
import instagram from "../img/insta.svg";
import linkedIn from "../img/linkedIn.svg";
import x from "../img/x.svg";

export default function Socialgrid() {
  return (
    <div className="social-grid gap-1">
      <div className="bg-tertiary-1 px-9 py-9 radius-2">
        <img src={instagram} alt="" />
      </div>
      <div className="bg-tertiary-1 px-9 py-9 radius-2">
        <img src={facebook} alt="" />
      </div>
      <div className="bg-tertiary-1 px-9 py-9 radius-2">
        <img src={x} alt="" />
      </div>
      <div className="bg-tertiary-1 px-9 py-9 radius-2">
        <img src={linkedIn} alt="" />
      </div>
    </div>
  );
}
