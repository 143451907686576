import React, { useState, useEffect } from "react";
import "./preloader.css";
import { addClassOnInViewport } from "../index.js";

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar">
      <div className="progress" style={{ width: `${progress}%` }} />
    </div>
  );
};

const Preloader = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(intervalId);
          // Simulate loading completion (you can replace this with your actual logic)
          setTimeout(() => {
            document
              .getElementsByClassName("preloader")[0]
              .classList.add("hidepreloader");
            setTimeout(() => {
              document.getElementsByClassName("preloader")[0].style.display =
                "none";
              addClassOnInViewport();
            }, 500);
          }, 500); // Delay before removing the preloader
          return 100;
        }
        return prevProgress + 5;
      });
    }, 20); // Adjust the interval for loading speed

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="preloader">
      <ProgressBar progress={progress} />
      <p className="progress">{progress}%</p>
    </div>
  );
};

export default Preloader;
