import React from "react";
import { Link } from "react-router-dom";
import SecondaryButton from "./SecondaryButton";

const scrollToTop = (event) => {
  event.preventDefault();
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export default function Practice(props) {
  return (
    <div className="practice-box mt-5 hidden" data-animation="fade-left">
      <img src={props.img} alt="Practice" />
      <div className="w-30 mw-100">
        <h1>{props.title}</h1>
        <p>{props.content}</p>
      </div>
      <Link onClick={scrollToTop} to={props.link}>
        <SecondaryButton text="Know More" />
      </Link>
    </div>
  );
}
