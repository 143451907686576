import React from "react";
import { Link } from "react-router-dom";
import call from "../img/call.svg";
import mail from "../img/Mail.svg";

const Teamcard = (props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optionally use smooth scrolling
    });
  };

  return (
    <div className="team-card">
      <Link onClick={scrollToTop} to={props.link}>
        <img src={props.image} alt={props.name}></img>
        <div className="team-card-content">
          <h3 className="light-1">{props.name}</h3>
          <h4 className="light light-2">{props.role}</h4>
        </div>
        <div className="team-card-hover">
          <p className="light-2 center">{props.text}</p>
          <div className="spaced-flex mt-1 light-1 team-card-hover-icons">
            <div className="spaced-flex center-flex">
              <img src={call} alt="call icon" />
            </div>
            <div className="center-flex">
              <img src={mail} alt="mail icon" />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Teamcard;
