import React from "react";
import { NavLink } from "react-router-dom";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <ul className="sidebar-menu">
        <li>
          <NavLink onClick={toggleSidebar} to="/">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink onClick={toggleSidebar} to="/About">
            About
          </NavLink>
        </li>
        <li>
          <NavLink onClick={toggleSidebar} to="/Team">
            Team
          </NavLink>
        </li>
        <li>
          <NavLink onClick={toggleSidebar} to="/Practice/Litigation">
            Practice
          </NavLink>
        </li>
        <li>
          <NavLink onClick={toggleSidebar} to="/Contact">
            Contact
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
